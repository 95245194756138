<template>
  <div class="com-answer-footer">
    <div class="com-answer-footer-inner">
      <div class="avatar-input">
        <Avatar
          :avatar="userInfo && userInfo.iconUrl"
          :account-type="userInfo && userInfo.accountType"
          :vip-type="userInfo && userInfo.vipType"
          @click.native="$checkAuthDeco($linkToPersonal, userInfo && userInfo.uid)"
          size="s"
        />
        <div
          @click="onPostCommentBlockClickHandler"
          class="fake-input ui-border"
        >
          添加评论
        </div>
      </div>
      <div class="other">
        <ul class="operate-list">
          <li
            :class="{'active': ifFollow}"
            @click="onActionHandler('follow')"
            class="operate-item"
          >
            <Icon
              :active="!!ifFollow"
              name="favor"
              size="l"
            />
            <span
              v-if="postInfo && postInfo.followCnt > 0"
              class="text"
            >{{ postInfo.followCnt | translateNumToText }}</span>
            <span
              v-else
              class="text"
            >收藏</span>
          </li>
          <li
            @click="onPostCommentBtnClickHandler"
            class="operate-item"
          >
            <Icon
              name="comment"
              size="l"
            />
            <span
              v-if="postInfo && postInfo.commentCnt > 0"
              class="text"
            >{{ postInfo.commentCnt | translateNumToText }}</span>
            <span
              v-else
              class="text"
            >评论</span>
          </li>
        </ul>
        <ul
          :class="{active: actionActive}"
          class="action-list"
        >
          <li
            v-show="!actionActive || actionActiveName==='like'"
            :class="{'active': actionActiveName==='like'}"
            @click="onActionHandler('like')"
            class="action-item"
          >
            <Icon
              :active="actionActiveName==='like'"
              name="up"
              class="action-icon"
              size="s"
            />
            <div class="text">
              {{ actionActiveName==='like' ? '已赞同' : '赞同' }}
              <span v-if="likeCount > 0">
                {{ likeCount | translateNumToText }}
              </span>
            </div>
          </li>
          <li
            v-show="!actionActive || actionActiveName==='dislike'"
            :class="{'active': actionActiveName==='dislike'}"
            @click="onActionHandler('dislike')"
            class="action-item"
          >
            <Icon
              :active="actionActiveName==='dislike'"
              name="down"
              class="action-icon"
              size="s"
            />
            <div class="text">
              {{ actionActiveName==='dislike' ? '已反对' : '反对' }}
              <span v-if="dislikeCount > 0">
                {{ dislikeCount | translateNumToText }}
              </span>
            </div>
          </li>
          <li
            v-show="!actionActive || actionActiveName==='happy'"
            :class="{'active': actionActiveName==='happy'}"
            @click="onActionHandler('happy')"
            class="action-item"
          >
            <Icon
              :active="actionActiveName==='happy'"
              name="soso"
              class="action-icon"
              size="s"
            />
            <div class="text">
              {{ actionActiveName==='happy' ? '微妙了' : '微妙' }}
              <span v-if="happyCount > 0">
                {{ happyCount | translateNumToText }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Avatar from '@/ui/avatar/index.vue';
import Icon from '@/ui/icon';

export default {
  name: 'ComAnswerFooter',
  components: {
    Avatar, Icon,
  },
  inject: ['reportData'],
  props: {
    postInfo: {
      type: Object,
      default: () => ({}),
    },
    ifFollow: {
      type: [Boolean, Number],
      default: false,
    },
    followCnt: {
      type: Number,
      default: 0,
    },
    ifLike: {
      type: [Boolean, Number],
      default: false,
    },
    likeCount: {
      type: Number,
      default: 0,
    },
    ifDislike: {
      type: [Boolean, Number],
      default: false,
    },
    dislikeCount: {
      type: Number,
      default: 0,
    },
    ifHappy: {
      type: [Boolean, Number],
      default: false,
    },
    happyCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    likeTime: {
      type: [String, Number],
      default: '0',
    },
    dislikeTime: {
      type: [String, Number],
      default: '0',
    },
    happyTime: {
      type: [String, Number],
      default: '0',
    },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    actionActive() {
      return this.ifLike || this.ifDislike || this.ifHappy;
    },
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
    }),
    actionActiveName() {
      // 防止出现后端返回互斥状态的bug，跟后端商议的临时解决方案是判断时间戳
      const likeTime = parseInt(this.likeTime, 10);
      const dislikeTime = parseInt(this.dislikeTime, 10);
      const happyTime = parseInt(this.happyTime, 10);
      let result = null;
      let resultTime = 0;
      if (this.ifDislike) result = 'dislike';
      if (this.ifLike) result = 'like';
      if (this.ifHappy) result = 'happy';
      if (this.ifLike && this.ifDislike) {
        if (likeTime > dislikeTime) {
          result = 'like';
          resultTime = likeTime;
        } else {
          result = 'dislike';
          resultTime = dislikeTime;
        }
      }
      if (result && this.ifHappy) {
        if (happyTime > resultTime) {
          result = 'happy';
          resultTime = happyTime;
        }
      }
      return result;
    },
  },
  methods: {
    onActionHandler(actionType) {
      switch (actionType) {
        case 'follow': // 关注或取消
          if (this.reportData) {
            this.reportData({
              moduleid: 13,
              eventtype: 3,
              extendid: 1,
            }, '回答详情页底部收藏点击');
          }
          break;
        case 'like': // 喜欢或取消
          if (this.ifLike) {
            if (this.reportData) {
              this.reportData({
                moduleid: 17,
                eventtype: 3,
                extendid: 2,
              }, '回答详情页底部点击取消喜欢');
            }
          } else if (this.reportData) {
            this.reportData({
              moduleid: 17,
              eventtype: 3,
              extendid: 1,
            }, '回答详情页底部点击喜欢');
            this.reportData({
              pageid: '1501',
              moduleid: 17,
              eventtype: 3,
              extendid: 1,
            }, '所有详情页底部点击赞同');
          }
          break;
        case 'dislike': // 不喜欢或取消
          if (this.ifDislike) {
            if (this.reportData) {
              this.reportData({
                moduleid: 18,
                eventtype: 3,
                extendid: 2,
              }, '回答详情页底部点击取消不喜欢');
            }
          } else if (this.reportData) {
            this.reportData({
              moduleid: 18,
              eventtype: 3,
              extendid: 1,
            }, '回答详情页底部点击不喜欢');
          }
          break;
        case 'happy': // 对应的就是微妙操作
          if (this.ifHappy) {
            if (this.reportData) {
              this.reportData({
                moduleid: 19,
                eventtype: 3,
                extendid: 2,
              }, '回答详情页底部点击取消微妙');
            }
          } else if (this.reportData) {
            this.reportData({
              moduleid: 19,
              eventtype: 3,
              extendid: 1,
            }, '回答详情页底部点击微妙');
          }
          break;
        default:
         //
      }
      if (this.loading) return;
      this.$checkAuthDeco(() => {
        switch (actionType) {
          case 'follow': // 关注或取消
            if (this.ifFollow) {
              this.$emit('postAction', 'unfollow');
            } else {
              this.$emit('postAction', 'follow');
            }
            break;
          case 'like': // 喜欢或取消
            if (this.ifLike) {
              this.$emit('postAction', 'unlike');
            } else {
              this.$emit('postAction', 'like');
            }
            break;
          case 'dislike': // 不喜欢或取消
            if (this.ifDislike) {
              this.$emit('postAction', 'undislike');
            } else {
              this.$emit('postAction', 'dislike');
            }
            break;
          case 'happy': // 对应的就是微妙操作
            if (this.ifHappy) {
              this.$emit('postAction', 'unhappy');
            } else {
              this.$emit('postAction', 'happy');
            }
            break;
          default:
          //
        }
      });
    },
    onPostCommentBlockClickHandler() {
      if (this.reportData) {
        this.reportData({
          moduleid: 15,
          eventtype: 3,
          extendid: 2,
        }, '详情页底部评论框点击');
        this.reportData({
          pageid: '1501',
          moduleid: 15,
          eventtype: 3,
          extendid: 1,
        }, '所有详情页通用评论点击');
      }
      this.$checkAuthDeco(() => {
        this.$emit('postComment');
      });
    },
    onPostCommentBtnClickHandler() {
      if (this.reportData) {
        this.reportData({
          moduleid: 15,
          eventtype: 3,
          extendid: 5,
        }, '详情页底部评论按钮点击');
        this.reportData({
          pageid: '1501',
          moduleid: 15,
          eventtype: 3,
          extendid: 1,
        }, '所有详情页通用评论点击');
      }
      this.$checkAuthDeco(() => {
        this.$emit('postComment');
      });
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
