<template>
  <DefaultLayout title="闪现一下">
    <PostLoading
      v-if="pageLoading"
    />
    <PostError
      v-else-if="postError"
      :custom-header="true"
      :text="postError"
      :status="postErrorStatus"
    />
    <div
      v-else
      class="page-answer-detail"
    >
      <PostHeader
        v-if="isApp"
        @menuClick="setShareData();setShareDataReport()"
      >
        <div
          ref="answerDetailHeaderRef"
          :style="headerStyle"
          class="page-answer-detail-header"
        >
          <div
            @click="onAllAnswerClickHandler"
            class="page-answer-detail-header-text"
          >
            <h4 class="page-answer-detail-header-text-main ui-nowrap">
              {{ postInfo.title }}
            </h4>
            <p class="page-answer-detail-header-text-desc">
              <span>{{ questionInfo ? questionInfo.answerCnt : '0' }} 个回答</span>
              <span class="icon-right-arrow" />
            </p>
          </div>
          <div
            v-if="questionInfo
              && questionInfo.extent
              && questionInfo.extent.userActionWithPost
              && questionInfo.extent.userActionWithPost.ifAnswer
              && !isUserEqualToAuthor"
            @click="$checkAuthDeco(onReviewAnswerClickHandler);"
            class="page-answer-detail-header-btn"
          >
            我的回答
          </div>
          <div
            v-else-if="!isUserEqualToAuthor"
            @click="$checkAuthDeco(onAnswerClickHandler);onAnswerClickHandlerReport()"
            class="page-answer-detail-header-btn"
          >
            写回答
          </div>
        </div>
      </PostHeader>
      <NoticeBar
        v-if="Number(postInfo.status) === 2"
        type="warning"
      >
        回答审核中，仅自己可见，稍后刷新页面查看
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 4"
        type="error"
      >
        回答审核未通过
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 7"
        type="error"
      >
        回答被管理员下架
      </NoticeBar>
      <div class="page-answer-detail-info">
        <PostTitle
          :title="postInfo.title"
          :flag="postInfo ? Number(postInfo.flag) : 0"
          @click.native="onAllAnswerClickHandler"
        />
        <GroupEntry
          v-if="groupInfo && !isFromGroup"
          :type="isFromGroup?'simple':'light'"
          :group-info="groupInfo"
          class="page-answer-detail-group-entry-bar"
        />
        <div
          @click="onAllAnswerClickHandler"
          class="page-answer-detail-info-all"
        >
          <p>查看全部 {{ questionInfo ? questionInfo.answerCnt : '0' }} 个回答</p>
          <span class="icon-arrow-right" />
        </div>
        <GroupEntry
          v-if="groupInfo && isFromGroup"
          :type="isFromGroup?'simple':'light'"
          :group-info="groupInfo"
          class="page-answer-detail-group-entry"
        />
        <div class="page-answer-detail-info-btn">
          <Button
            @click="$checkAuthDeco(onInviteClickHandler);onInviteClickHandlerReport()"
            class="btn-item"
            icon="invite"
            size="l"
          >
            邀请回答
          </Button>
          <Button
            v-if="isUserEqualToAuthor && $compareVersion('1.2') > -1"
            :type="'secondary'"
            :icon="'write'"
            @click="$checkAuthDeco(onEditAnswerClickHandler);onEditAnswerClickHandlerReport();"
            @updated.once="onEditAnswerBtnLayout"
            class="btn-item"
            size="l"
            clickable
          >
            修改回答
          </Button>
          <Button
            v-else-if="isShowReviewBtn"
            :type="'secondary'"
            :icon="'review'"
            :disabled="isUserEqualToAuthor"
            @click="$checkAuthDeco(onReviewAnswerClickHandler);"
            class="btn-item"
            size="l"
            clickable
          >
            我的回答
          </Button>

          <Button
            v-else
            @click="$checkAuthDeco(onAnswerClickHandler);onAnswerClickHandlerReport()"
            type="secondary"
            class="btn-item"
            icon="write"
            size="l"
          >
            写回答
          </Button>
        </div>
      </div>
      <div class="gap" />
      <div
        ref="detailContentRef"
        :style="{maxHeight:`${contentMaxHeight}px`}"
        class="page-answer-detail-content"
      >
        <PostAuthor
          :creator-info="creatorInfo"
          :avatar="creatorInfo.iconUrl"
          :nickname="creatorInfo.nickName"
          :uid="creatorInfo.uid"
          :view-num="postInfo.readCnt"
          :user-level="creatorInfo.level"
          :account-type="creatorInfo.accountType"
          :follow-status="creatorInfo.followStatus"
          :vip-type="creatorInfo.vipType"
          :publish-time="parseInt(postInfo.createTime)"
          :current-time="parseInt(new Date().getTime() / 1000)"
          :data-source="dataSource"
          @changeUserFollowStatus="onChangeUserFollowStatusHandler"
          class="post-author"
          follow-btn-type="secondary"
        />
        <div
          v-if="isOfficialLike"
          class="official-like-wrapper"
        >
          <div class="official-like">
            <Icon
              size="s"
              name="hot-blue"
            />
            官方点赞了该回答
          </div>
        </div>
        <PostText
          :content="contentComputed"
          :pics="postInfo.pics"
          :channel="postInfo.channel"
          class="page-answer-detail-content-rich"
          post-main="picture"
        />
        <div class="page-answer-detail-content-desc">
          <span class="ip-region">发布于 {{ postInfo.ipRegionStr || '未知' }}</span>
          <!--<div class="edit-date">-->
          <!-- 编辑于 {{ parseInt(postInfo.modifiedTime) > parseInt(postInfo.createTime)
          ? postInfo.modifiedTime : postInfo.createTime | getLocalDate }} -->
          <!-- </div>-->
          <div
            v-show="(!userInfo || Number(userInfo.uid) !== Number(creatorInfo.uid))"
            @click="onAccuseClickHandler();onAccuseClickHandlerReport()"
          >
            举报回答
          </div>
        </div>

        <!-- 展开全文 -->
        <template v-if="isShowOpen">
          <div
            v-if="!isOpenText"
            class="post-status-open"
          >
            <div class="post-status-open-top" />
            <div class="post-status-open-bottom">
              <span @click="onOpenTextHandler">展开全文</span>
              <img
                @click="onOpenTextHandler"
                src="./img/icon-arrow-bottom.png"
                class="post-status-icon"
              >
            </div>
          </div>
          <div
            v-else
            class="post-status-close"
          >
            <span @click="onCloseTextHandler">收起</span>
            <img
              @click="onCloseTextHandler"
              src="./img/icon-arrow-top.png"
              class="post-status-icon"
            >
          </div>
        </template>
      </div>

      <div style="padding: 0 18px;">
        <div
          class="ui-border-t"
        />
      </div>

      <!-- 排序 异步渲染组件 -->
      <template v-for="item in moduleOrder">
        <PostWelfareV2
          v-if="!isApp && item===1"
          :key="item"
          :game-code="groupInfo.gameCode"
        />
        <PostComment
          ref="postCommentRef"
          v-else-if="item===2"
          :key="item"
          :anchor-watcher="[postInfo]"
          :post-main="'picture'"
          :post-id="postId"
          :comment-total-number="postInfo.commentCnt"
          :author-info="creatorInfo"
          :bottom-distance="bottomDistance"
          :group-info="groupInfo"
          @updateCommentAmount="onUpdateCommentAmountHandler"
          class="page-answer-detail-comment"
        />
        <PostRcmd
          v-else-if="!isApp && item===3"
          :key="item"
          :post-id="postInfo.id"
          :group-id="groupInfo.id"
          :group-name="groupInfo.name"
          :kk-gid="groupInfo.kkGid"
        />
        <PostActivity
          v-else-if="!isApp && item===4"
          :key="item"
        />
      </template>

      <AnswerFooter
        ref="postFooterRef"
        v-if="isApp"
        :if-follow="postAction ? postAction.ifFollow : false"
        :if-like="postAction ? postAction.ifLike : false"
        :like-count="postInfo.likeCnt"
        :if-dislike="postAction ? postAction.ifDislike : false"
        :dislike-count="postInfo.negativeCnt"
        :if-happy="postAction ? postAction.ifHappy : false"
        :happy-count="postInfo.happyCnt"
        :loading="postActionLoading"
        :like-time="postAction ? postAction.likeTime : '0'"
        :dislike-time="postAction ? postAction.dislikeTime : '0'"
        :happy-time="postAction ? postAction.happyTime : '0'"
        :post-info="postInfo"
        @postAction="$checkAuthDeco(postActionHandler, ...arguments)"
        @postComment="$checkAuthDeco(onPostCommentHandler)"
        class="page-answer-detail-footer ui-border-t"
      />
      <Dialog
        v-model="deleteDialogStatus"
        :confirm="true"
        :confirm-text-style="{color: '#303133'}"
        :confirm-text="'确认删除'"
        @confirm="onDeleteDialogConfirmHandler"
      >
        确认要删除吗？
      </Dialog>
      <PostAccuse
        v-model="accusePopupStatus"
        :post-main="'picture'"
        :post-info="postInfo"
      />
      <ShareDownload
        v-if="!isApp"
        :channel="'sxnrhuida'"
      />
      <ManageDialog
        v-model="isShowManageDialog"
        :post-info="postInfo"
        :creator-info="creatorInfo"
        :group-info="groupInfo"
        @update="onManageUpdate"
      />
    </div>
  </DefaultLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DefaultLayout from '@/layouts/default/index.vue';
import PostHeader from '@/component/post-header/index.vue';
import PostTitle from '@/component/post-title/index.vue';
import PostAuthor from '@/component/post-author/index.vue';
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import GroupEntry from '@/component/group-entry/index.vue';
import AnswerFooter from '@/component/answer-footer/index.vue';
import ShareDownload from '@/component/share-download/index.vue';
import NoticeBar from '@/ui/notice-bar/index.vue';
import Button from '@/ui/button/index.vue';
import * as questionApi from '@/api/question';
import postApi from '@/api/post';
import Dialog from '@/ui/dialog/index.vue';
import PostAccuse from '@/component/post-accuse/index.vue';
import postDetail from '@/plugin/mixin/postDetail';
import postReportData from '@/plugin/mixin/postReportData';
import {
  postComment, setRightButton, showShareDialog, showShareDialogV2, postAnswer, popBack,
  send as noticeSend, sendGlobalNotification,
} from '@/jsbridge';
import { isBitTrue, throttle } from '@/util/util';
import { reportEnum } from '@/api/report';
import { logger } from '@/util/logger';
import { checkEnv } from '@/util/browser-env';
import { getShareUrl } from '@/util/get-base-url';
import { setH5Share } from '@/util/h5-share';
import compareVersion from '@/util/compareVersion';
import aegis from '@/util/aegis';
import uniOpenUrl from '@/util/uni-open-url';
import unicodeString from '@/util/unicode-string';
import Icon from '@/ui/icon';
import ManageDialog from '@/component/post-manage/manage-dialog/index.vue';
import PostText from '@/component/post-text/index.vue';
import { pageStore, PAGE_STORE_MODULE_NAME, pageMixin } from './store';

const PostComment = () => ({
  component: import('@/component/post-comment/index.vue'),
});
const PostActivity = () => ({
  component: import('@/component/post-activity/index.vue'),
});
const PostRcmd = () => ({
  component: import('@/component/post-rcmd/index.vue'),
});
const PostWelfareV2 = () => ({
  component: import('@/component/post-welfare-v2/index.vue'),
});
export default {
  name: 'PageAnswerDetail',
  components: {
    Dialog,
    PostHeader,
    PostTitle,
    PostAuthor,
    PostComment,
    AnswerFooter,
    ShareDownload,
    Button,
    PostLoading,
    PostError,
    PostAccuse,
    NoticeBar,
    DefaultLayout,
    GroupEntry,
    Icon,
    ManageDialog,
    PostText,
    PostActivity,
    PostRcmd,
    PostWelfareV2,
  },
  mixins: [postDetail, postReportData, pageMixin],
  data() {
    return {
      headerStyle: {},
      pageError: false,
      postId: '',
      questionId: '',
      postType: 3,
      // postInfo: null,
      // creatorInfo: null,
      commentList: null,
      // groupInfo: null,
      // postAction: null,
      // postAction: {
      //   ifFollow: false,
      //   ifLike: false,
      //   ifDislike: false,
      //   ifHappy: false,
      //   likeTime, dislikeTime, happyTime
      // },
      postActionLoading: false,
      questionInfo: null,
      bottomDistance: 60,
      // 删除帖子
      deletePostLoading: false,
      deleteDialogStatus: false,
      // 举报帖子
      accusePopupStatus: false,
      reportPageId: reportEnum.pageid.ANSWER_DETAIL,
      reportContentType: reportEnum.contenttype.ANSWER_DETAIL,
      retryCount: 0,
      maxRetryCount: 0,
      retryTimer: null,
      pageErrorText: '系统繁忙',
      postErrorStatus: 'fail',
      pageLoaded: false,
      isFromGroup: false, // 是否从圈子打开, http://tapd.oa.com/GamingCommunity/prong/stories/view/1020419237859949769
      isShowManageDialog: false,
      prevId: '', // 上级来源页ID
      isShowOpen: false, // 是否展示展开全文
      isOpenText: false,
      defaultMaxHeight: 100000000000,
      contentMaxHeight: this.defaultMaxHeight, // 帖子内容的最大高度
      defaultModuleOrder: [2], // 默认只有评论模块
      moduleOrder: this.defaultModuleOrder, // 模块顺序  1 礼包，2 评论，3 相关内容，4 活动
      groupId: null,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
      hasPerm: state => state.manager?.hasPerm,
    }),
    contentComputed() {
      // console.log('contentComputed', this.postInfo);
      if (this.postInfo.content) {
        // console.log('this.postInfo.content', this.postInfo.content);
        return this.postInfo.content;
      } if (this.postInfo.extent && this.postInfo.extent.preRichText) {
        // const o = document.createElement('div');
        // o.innerHTML = this.postInfo.extend.preRichText;
        // return o.innerHTML;
        // console.log('this.postInfo.extent.preRichText', this.postInfo.extent.preRichText);
        const closeTagIndex = this.postInfo.extent.preRichText.lastIndexOf('>');
        if (closeTagIndex === -1) {
          return this.postInfo.extent.preRichText;
        }
        return this.postInfo.extent.preRichText.slice(0, closeTagIndex + 1);
      }
      // console.log('this.postInfo.summary', this.postInfo.summary);
      return this.postInfo.summary;
    },
    isOfficialLike() {
      // bit10:被官方点赞
      return this.postInfo && this.postInfo.flag && isBitTrue(this.postInfo.flag, 10);
    },
    isUserEqualToAuthor() {
      const isHave = this.userInfo && this.userInfo.uid && this.postInfo;
      return isHave && (Number(this.userInfo.uid) === Number(this.postInfo.creatorId));
    },
    postError() {
      if (this.pageError || !this.postInfo) {
        return this.pageErrorText;
      }
      if (this.postInfo && this.postInfo.status === 2 && !this.isUserEqualToAuthor) {
        return '回答正在审核';
      }
      if (this.postInfo && this.postInfo.status === 4 && !this.isUserEqualToAuthor) {
        return '回答审核未通过';
      }
      if (this.postInfo && this.postInfo.status === 7 && !this.isUserEqualToAuthor) {
        return '回答被管理员下架';
      }
      if (this.postInfo && this.postInfo.status === 9) {
        return '回答已被删除';
      }
      if ([2, 4, 6, 7, 9].indexOf(Number(this.postInfo.status)) === -1) {
        return `状态异常: ${this.postInfo.status}`;
      }
      return false;
    },
    isShowReviewBtn() {
      const { questionInfo } = this;
      return questionInfo?.extent?.userActionWithPost?.ifAnswer;
    },
  },
  watch: {
    userInfo(newVal, oldVal) {
      if (this.pageLoaded) {
        if (newVal && !oldVal) {
          // 从无到有就是登录
          this.initPageData({
            silent: true,
          });
        }
      }
    },
    groupId(val) {
      if (!this.isApp && val) this.getContentShareModuleOrder();
    },
  },
  storeName: PAGE_STORE_MODULE_NAME,
  storeModules: {
    [PAGE_STORE_MODULE_NAME]: pageStore,
  },
  asyncData({ store, route }, preData) {
    if (!store.state[PAGE_STORE_MODULE_NAME]) {
      store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);
    }
    const { answerId } = route.query;
    return store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
      params: {
        answerId,
        postType: 1,
      },
      preData,
    });
  },
  beforeDestroy() {
    this.unbindScrollHandler();
    clearTimeout(this.retryTimer);
    this.pageError = false;
    this.resetData();
  },
  mounted() {
    this.resetData();
    this.prevId = this.$route.query.prev_id || '';
    this.postId = this.$route.query.answerId;
    this.questionId = this.$route.query.questionId;
    this.isFromGroup = this.$route.query.pageFrom === '1306';
    this.postType = 3;
    this.scrollHandler = throttle(() => {
      if (!this.$refs.answerDetailHeaderRef) return;
      const h = this.$refs.answerDetailHeaderRef.offsetHeight;
      const scrollTarget = this.getScrollEventTarget(this.$el);
      const { scrollTop, scrollY } = scrollTarget;
      const t = scrollTop || scrollY || 0; // 当滚动对象是window的时候要取scrollY
      const startRate = 0.4; // 从标题隐藏 40% 的时候开始变化
      let rate = 0;
      if (t >= h) {
        rate = 1;
      } else if (t <= h * startRate) {
        rate = 0;
      } else {
        rate = Math.round(((t - h * startRate) / (h - h * startRate)) * 100) / 100;
      }
      this.headerStyle = {
        transform: `translateY(${`${40 + rate * -40}%`})`,
        opacity: rate,
        pointerEvents: rate > 0.1 ? 'auto' : 'none',
      };
    }, 17);
    if (this.postId && this.questionId) {
      if (this.isApp) {
        this.$store.dispatch('user/getUserInfo')
          .then(() => {
            if (this.userInfo) {
              this.initPageData();
            } else {
              this.initPageDataNotLogin();
            }
          })
          .catch((err) => {
            logger.err('getUserInfo', err);
          });
      } else {
        this.reportPageId = reportEnum.pageid.ANSWER_DETAIL_H5;
        this.initPageDataH5();
      }
    } else {
      this.pageLoading = false;
      this.pageError = true;
    }
    // 判断详情内容高度是否> 335(设计给的固定高度) + 20 ("展开全文"高度)=355
    if (!this.isApp) {
      this.$nextTick(() => {
        const height = this.$refs.detailContentRef.offsetHeight;
        if (Number(height) >= 355) {
          this.isShowOpen = true;
          this.contentMaxHeight = 355;
        }
      });
    }
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
    }),
    resetData() {
      this.headerStyle = {};
      this.pageError = false;
      this.postId = '';
      this.questionId = '';
      this.postType = 3;
      this.commentList = null;
      this.postActionLoading = false;
      this.questionInfo = null;
      this.bottomDistance = 60;
      this.deletePostLoading = false;
      this.deleteDialogStatus = false;
      this.accusePopupStatus = false;
      this.reportPageId = reportEnum.pageid.ANSWER_DETAIL;
      this.reportContentType = reportEnum.contenttype.ANSWER_DETAIL;
      this.retryCount = 0;
      this.maxRetryCount = 0;
      this.retryTimer = null;
      this.pageErrorText = '系统繁忙';
      this.postErrorStatus = 'fail';
      this.pageLoaded = false;
      this.isShowOpen = false;
      this.isOpenText = false;
      this.contentMaxHeight = this.defaultMaxHeight;
      this.moduleOrder = this.defaultModuleOrder;
    },
    onManageUpdate() {
      this.getPostInfo({
        params: {
          flag: 2,
        },
      }, true);
    },
    initPageData({ silent = false } = {}) {
      Promise.all([this.getPostInfo(), this.getQuestionInfo()])
        .then(([res1, res2]) => {
          this.pageLoaded = true;
          if (res1 !== 'error' && res2 !== 'error') {
            this.pageLoading = false;
            this.questionInfo = res2.info[0].postInfo;
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '回答详情页曝光');
            // 数据上报，operid: 1501000010101
            this.reportData({
              operid: '1501000010101',
            }, '所有内容详情页页面曝光');
            this.pageError = false;
            this.setRightButton();
            this.$nextTick(() => {
              this.bindScrollHandler();
              this.initPageDom();
            }, 0);
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageData({ silent: true });
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            if (!silent) {
              this.pageError = true;
              aegis.report('详情页打开异常');
            }
          }
          return true;
        });
    },
    initPageDataNotLogin() {
      Promise.all([this.getPostInfo(), this.getQuestionInfo()])
        .then(([res1, res2]) => {
          this.pageLoaded = true;
          if (res1 !== 'error' && res2 !== 'error') {
            this.pageLoading = false;
            this.questionInfo = res2.info[0].postInfo;
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            this.pageError = false;
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '回答详情页曝光');
            // 数据上报，operid: 1501000010101
            this.reportData({
              operid: '1501000010101',
            }, '所有内容详情页页面曝光');
            this.setRightButton();
            this.$nextTick(() => {
              this.bindScrollHandler();
              this.initPageDom();
            }, 0);
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataNotLogin();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          return true;
        });
    },
    initPageDataH5() {
      Promise.all([this.getPostInfo({
        params: {
          flag: 2,
        },
      }), this.getQuestionInfo()])
        .then(([res1, res2]) => {
          this.pageLoaded = true;
          if (res1 !== 'error' && res2 !== 'error') {
            this.pageLoading = false;
            this.questionInfo = res2.info[0].postInfo;
            this.pageError = false;
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '回答详情页曝光');
            this.reportData({
              pageid: '2000',
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
            }, '所有内容详情页页面曝光');
            setH5Share({
              shareTitle: this.postInfo.title || '闪现一下',
              shareDesc: unicodeString.slice(this.postInfo.summary, 200) || '来自闪现一下，来闪现，从不落单~',
              shareLink: getShareUrl(),
              shareImgUrl: this.getPostCoverUrl(this.postInfo) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
            });
            this.$nextTick(() => {
              this.initPageDom();
            }, 0);
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataH5();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          if (res2 !== 'error') {
            this.questionInfo = res2.info[0].postInfo;
          }
          return true;
        });
    },
    getScrollEventTarget(element) {
      let currentNode = element;
      // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
      while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        const { overflowY } = getComputedStyle(currentNode);
        if (overflowY === 'scroll' || overflowY === 'auto') {
          return currentNode;
        }
        currentNode = currentNode.parentNode;
      }
      return window;
    },
    bindScrollHandler() {
      const scrollTarget = this.getScrollEventTarget(this.$el);
      scrollTarget.addEventListener('scroll', this.scrollHandler);
    },
    unbindScrollHandler() {
      const scrollTarget = this.getScrollEventTarget(this.$el);
      scrollTarget.removeEventListener('scroll', this.scrollHandler);
    },
    getPostInfo({ params = {} } = {}, isForceUpdate) {
      if (this.$route.query.localDataKey && !isForceUpdate) {
        try {
          let preLoadData = localStorage.getItem(this.$route.query.localDataKey);
          logger.info('获取本地答案数据', preLoadData);
          if (preLoadData) {
            preLoadData = JSON.parse(preLoadData);
            localStorage.removeItem(this.$route.query.localDataKey);
          }
          if (preLoadData && preLoadData.postInfo) {
            logger.info('使用了本地数据');
            this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updatePostInfo`, preLoadData.postInfo);
            this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updateCreatorInfo`, preLoadData.creator);
            this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updatePostAction`, {
              ifLike: 0, ifAnswer: 0, ifHappy: 0, ifFollow: 0, ifDislike: 0,
            });
            return Promise.resolve({
              info: [{
                groupInfo: {},
                ...preLoadData,
              }],
            });
          }
        } catch (error) {
          logger.error('获取本地答案失败', error);
        }
      }
      const { answerId } = this.$route.query;
      return new Promise((resolve) => {
        this.$store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
          params: {
            answerId,
            userId: this.userInfo ? this.userInfo.uid : 0,
            ...params,
          },
        }).then((res) => {
          if (res.info && res.info[0] && res.info[0].postInfo) {
            this.groupId = res.info[0].groupInfo.id;
            return resolve(res);
          }
          logger.error('getPostInfo error', res);
          // this.pageErrorText = '系统繁忙(null)';
          return resolve('error');
        }, (err) => {
          logger.error('getPostInfo error', err);
          this.postErrorStatus = 'fail';
          if (err.status === 200 && err.data) {
            this.pageErrorText = '系统繁忙';
          } else if (err.response) {
            this.pageErrorText = '系统繁忙';
          } else if (err.request) {
            this.pageErrorText = '网络竟然崩溃了，换个姿势刷新试试';
            this.postErrorStatus = 'network';
          } else {
            this.pageErrorText = '系统繁忙';
          }
          return resolve('error');
        });
      });
    },
    getQuestionInfo() {
      return questionApi.getQuestionInfoById({
        postId: this.questionId,
        userId: this.userInfo && this.userInfo.uid,
      }).then((res) => {
        if (res.info && res.info[0] && res.info[0].postInfo) {
          return Promise.resolve(res);
        }
        logger.error('getQuestionInfo error', res);
        // this.pageErrorText = '系统繁忙(null)';
        return Promise.resolve('error');
      }, (err) => {
        logger.error('getQuestionInfo error', err);
        // if (err.status === 200 && err.data) {
        //   this.pageErrorText = `系统繁忙(ret:${err.data.ret})`;
        // } else if (err.response) {
        //   this.pageErrorText = `系统繁忙(${err.response.status})`;
        // } else if (err.request) {
        //   this.pageErrorText = '系统繁忙(Req)';
        // } else {
        //   this.pageErrorText = '系统繁忙';
        // }
        return Promise.resolve('error');
      });
    },
    getPostAction() {
      return new Promise((resolve) => {
        postApi.getPostAction({
          userId: this.userInfo && this.userInfo.uid,
          postId: this.postId,
        }).then(res => resolve(res), () => resolve('error'));
      });
    },
    postActionHandler(action) {
      if (this.postActionLoading) return false;
      this.postActionLoading = true;
      postApi.postAction({
        postId: this.postInfo.id,
        postType: this.postInfo.type,
        postCreatorId: this.creatorInfo.uid,
        userId: this.userInfo.uid,
        groupId: this.postInfo.groupId,
        action,
      }).then(() => {
        this.sendNotice({
          operate: 'postAction',
          data: action,
        });
        switch (action) {
          case 'like':
            this.postAction.ifLike = true;
            this.postAction.ifDislike = false;
            this.postAction.ifHappy = false;
            this.postInfo.likeCnt += 1;
            this.sendNotice({
              operate: 'likeCnt',
              data: this.postInfo.likeCnt,
            });
            break;
          case 'unlike':
            this.postAction.ifLike = false;
            this.postAction.ifDislike = false;
            this.postAction.ifHappy = false;
            this.postInfo.likeCnt = this.postInfo.likeCnt > 0 ? this.postInfo.likeCnt - 1 : 0;
            this.sendNotice({
              operate: 'likeCnt',
              data: this.postInfo.likeCnt,
            });
            break;
          case 'dislike':
            this.postAction.ifDislike = true;
            this.postAction.ifLike = false;
            this.postAction.ifHappy = false;
            this.postInfo.negativeCnt += 1;
            this.sendNotice({
              operate: 'negativeCnt',
              data: this.postInfo.likeCnt,
            });
            break;
          case 'undislike':
            this.postAction.ifDislike = false;
            this.postAction.ifLike = false;
            this.postAction.ifHappy = false;
            this.postInfo.negativeCnt = this.postInfo.negativeCnt > 0 ? this.postInfo.negativeCnt - 1 : 0;
            this.sendNotice({
              operate: 'negativeCnt',
              data: this.postInfo.likeCnt,
            });
            break;
          case 'follow':
            this.postAction.ifFollow = true;
            this.postInfo.followCnt += 1;
            this.sendNotice({
              operate: 'followCnt',
              data: this.postInfo.likeCnt,
            });
            break;
          case 'unfollow':
            this.postAction.ifFollow = false;
            this.postInfo.followCnt = this.postInfo.followCnt > 0 ? this.postInfo.followCnt - 1 : 0;
            this.sendNotice({
              operate: 'followCnt',
              data: this.postInfo.likeCnt,
            });
            break;
          case 'happy':
            this.postAction.ifHappy = true;
            this.postAction.ifLike = false;
            this.postAction.ifDisike = false;
            this.postInfo.happyCnt += 1;
            this.sendNotice({
              operate: 'happyCnt',
              data: this.postInfo.happyCnt,
            });
            break;
          case 'unhappy':
            this.postAction.ifHappy = false;
            this.postAction.ifLike = false;
            this.postAction.ifDisike = false;
            this.postInfo.happyCnt = this.postInfo.happyCnt > 0 ? this.postInfo.happyCnt - 1 : 0;
            this.sendNotice({
              operate: 'happyCnt',
              data: this.postInfo.happyCnt,
            });
            break;
          default:
        }
      }, (err) => {
        if (err.data && err.data.msg) {
          this.$toast(err.data.msg);
        } else {
          this.$toast('操作失败');
        }
      })
        .finally(() => {
          this.postActionLoading = false;
        });
      return true;
    },
    onGetPostAction() {
      this.getPostAction()
        .then((res) => {
          if (res !== 'error') this.postAction = res;
        });
    },
    initPageDom() {
      this.bottomDistance = this.$refs.postFooterRef ? this.$refs.postFooterRef.$el.offsetHeight : 0;
    },
    // 点击底部添加评论
    onPostCommentHandler() {
      let attribute; // 1:普通，2:作者，3:官方，4:置顶
      // accountType:0 为普通用户，1 官方一级 2 官方二级 3 官方个人 4 媒体官方 5 媒体个人
      if (Number(this.userInfo.accountType) > 0 && Number(this.userInfo.accountType) < 4) {
        attribute = 3;
      } else if (Number(this.userInfo.uid) === Number(this.creatorInfo.uid)) {
        attribute = 2;
      } else {
        attribute = 1;
      }
      let { postCommentRef } = this.$refs;
      if (Array.isArray(postCommentRef)) [postCommentRef] = postCommentRef;
      postComment({
        type: 2,
        parentId: this.postId,
        rootId: this.postId,
        commentatorId: this.creatorInfo.uid,
        attribute,
        content: '', // 一级评论不传内容
        nickname: this.creatorInfo.nickName,
        groupId: this.postInfo.groupId,
      }).then((commentData) => {
        logger.info('postCommentData', commentData);
        // 终端弹窗
        postCommentRef.addCommentItem({
          commentData,
        });
        this.reportData({
          moduleid: 15,
          eventtype: 6,
          extendid: 1,
          ext1: 1,
        }, '回答详情页评论成功');
        this.reportData({
          pageid: '1501',
          moduleid: 15,
          eventtype: 6,
          extendid: 1,
          ext1: 1,
        }, '所有详情页评论成功');
      });
    },
    onUpdateCommentAmountHandler({ num } = {}) {
      this.postInfo = {
        ...this.postInfo,
        commentCnt: this.postInfo.commentCnt + Number(num),
      };
      this.sendNotice({
        operate: 'updateCommentAmount',
        data: this.postInfo.commentCnt,
      });
      this.sendNotice({
        operate: 'commentCnt',
        data: this.postInfo.commentCnt,
      });
    },
    onChangeUserFollowStatusHandler(payload) {
      this.creatorInfo = {
        ...this.creatorInfo,
        followStatus: payload,
      };
    },
    // 更新底部点赞收藏内容
    onUpdatePostFooterHandler(action) {
      this.postAction = action;
      this.sendNotice({
        operate: 'postAction',
        data: action,
      });
    },
    onAllAnswerClickHandler() {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 1,
      }, '回答详情页点击查看所有回答');
      if (this.isApp) {
        const pageFrom = Number(this.$route.query.pageFrom);
        if (pageFrom === reportEnum.pageid.QUESTION_DETAIL) {
          if (this.isIOS) {
            popBack();
          } else if (this.isAndroid) {
            popBack();
          }
        } else {
          const targetUrl = `${window.location.origin}/community/question-detail?questionId=${this.questionId}&pageFrom=${reportEnum.pageid.ANSWER_DETAIL}&_gcwv=2`;
          uniOpenUrl(targetUrl);
        }
      } else {
        this.$router.push({
          name: 'community-question-detail',
          query: {
            questionId: this.questionId,
            pageFrom: reportEnum.pageid.ANSWER_DETAIL,
            _gcwv: 2,
          },
        });
      }
    },
    onInviteClickHandlerReport() {
      this.reportData({
        moduleid: 20,
        eventtype: 3,
        extendid: 1,
      }, '回答详情页点击邀请回答');
    },
    onInviteClickHandler() {
      if (this.isApp) {
        const url = `${window.location.origin}/community/answer-invite/?questionId=${this.questionId}&pageFrom=${reportEnum.pageid.ANSWER_DETAIL}&_gcwv=2`;
        uniOpenUrl(url);
      } else {
        this.$router.push({
          name: 'community-answer-invite',
          query: {
            questionId: this.questionId,
            pageFrom: reportEnum.pageid.ANSWER_DETAIL,
            _gcwv: 2,
          },
        });
      }
    },
    onAnswerItemClickHandler(answerInfo) {
      if (this.isApp) {
        const targetUrl = `${window.location.origin}/community/answer-detail/?answerId=${answerInfo.postInfo.id}&questionId=${this.questionId}&pageFrom=${reportEnum.pageid.ANSWER_DETAIL}&_gcwv=2`;
        uniOpenUrl(targetUrl);
      } else {
        this.$router.push({
          name: 'community-answer-detail',
          query: {
            answerId: answerInfo.postInfo.id,
            questionId: this.questionId,
            pageFrom: reportEnum.pageid.ANSWER_DETAIL,
            _gcwv: 2,
          },
        });
      }
    },
    onAnswerClickHandlerReport() {
      this.reportData({
        moduleid: 21,
        eventtype: 3,
        extendid: 1,
      }, '回答详情页点击写回答');
    },
    onAnswerClickHandler() {
      // console.log('onAnswerClickHandler', this.isUserEqualToAuthor, this.userInfo.uid, this.postInfo.creatorId);
      postAnswer({
        postId: this.questionId,
        groupId: this.questionInfo.groupId,
        title: this.questionInfo.title,
      }).then((res) => {
        const answerInfo = {
          creator: this.userInfo,
          postInfo: res,
        };
        this.onAnswerItemClickHandler(answerInfo);
        if (this.questionInfo.extent && this.questionInfo.extent.userActionWithPost) {
          this.questionInfo.extent.userActionWithPost.ifAnswer = true;
          this.questionInfo.extent.userActionWithPost.answerId = res.id;
        }
        this.sendNotice({
          operate: 'addAnswer',
          data: answerInfo,
        });
      });
    },
    setRightButton() {
      if (this.postInfo.status === 9) return false;
      setRightButton({
        callback: () => {
          this.setShareData();
        },
      });
      return true;
    },
    onReviewAnswerClickHandler() {
      const myAnswerId =  this.questionInfo?.extent?.userActionWithPost?.answerId;
      if (!myAnswerId) {
        return;
      }
      if (this.isUserEqualToAuthor) {
        this.$toast('当前已是你的回答');
        return;
      }
      if (this.isApp) {
        const targetUrl = `${window.location.origin}/community/answer-detail/?answerId=${myAnswerId}&questionId=${this.questionInfo.id}&pageFrom=${reportEnum.pageid.ANSWER_DETAIL}&_gcwv=2`;
        uniOpenUrl(targetUrl);
      } else {
        this.$router.push({
          name: 'community-answer-detail',
          query: {
            answerId: myAnswerId,
            questionId: this.questionInfo.id,
            pageFrom: reportEnum.pageid.ANSWER_DETAIL,
            _gcwv: 2,
          },
        });
      }
    },
    setShareDataReport() {
      // 右上角按钮点击上报
      this.reportData({
        pageid: '1501',
        moduleid: 1,
        eventtype: 3,
        extendid: 1,
      }, '回答详情页点击右上角按钮');
    },
    setShareData() {
      // 审核需要举报
      // if (this.$store.state.isAndroid) {
      //   if (!this.userInfo) {
      //     this.$checkAuthDeco(() => {
      //       this.onGetPostAction();
      //     });
      //     return false;
      //   }
      //   if ((!this.postAction || Object.keys(this.postAction).length === 0)) {
      //     this.onGetPostAction();
      //     this.$toast('系统繁忙，请稍候再试');
      //     return false;
      //   }
      // }
      let action = [
        {
          type: 'follow',
          name: this.postAction && this.postAction.ifFollow ? '已收藏' : '收藏',
          native: 0,
        },
      ];
      if (this.userInfo && Number(this.userInfo.uid) === Number(this.creatorInfo.uid)) {
        action.push({
          type: 'delete',
          name: '删除',
          native: 0,
        });
      } else {
        action.push({
          type: 'accuse',
          name: '举报',
          native: 0,
        });
        action.push({
          type: 'block',
          name: '屏蔽',
          native: 0,
        });
      }
      action.push({
        type: 'copyLink',
        name: '复制链接',
        native: 1,
      });
      let showShareDialogFunc = showShareDialog;
      if (this.$compareVersion('1.8.2') >= 0) {
        showShareDialogFunc = showShareDialogV2;
        action = [{
          type: 'tgFriend',
        }].concat(action);
        if (this.hasPerm) { // todo 管理员判断
          action.push({
            type: 'custom',
            title: '管理内容',
            icon: decodeURIComponent('https://static.gameplus.qq.com/business/iconh5/manage/icon_94_manege.png'),
            id: 1,
            native: 0,
          });
          this.reportData({
            operid: '1501000830201',
          }, '内容详情页操作面板管理按钮曝光');
        }
      }
      const shareContent = {
        shareVisible: this.postInfo.status === 6 ? 1 : 0, // 0 分享不可见， 1 可见
        title: this.postInfo.title || '闪现一下',
        summary: unicodeString.slice(this.postInfo.summary, 200) || '来自闪现一下，来闪现，从不落单~',
        targetUrl: getShareUrl(),
        thumbUrl: this.getPostCoverUrl(this.postInfo) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
        action,
        postInfo: {
          postId: this.postId,
          postType: this.postType,
          postCreatorId: (this.creatorInfo && this.creatorInfo.uid),
          groupId: this.postInfo.groupId,
          action: this.postAction && this.postAction.ifFollow ? 'follow' : 'unfollow',
          userId: (this.userInfo && this.userInfo.uid) || 0,
        },
      };


      logger.info('showShareDialog', shareContent);
      showShareDialogFunc(shareContent)
        .then((res2) => {
          // console.log(`showShareDialog: ${JSON.stringify(res2)}`);
          if (res2.result === 0) {
            switch (res2.data.type) {
              case 'follow':
                this.$checkAuthDeco(() => {
                  this.$refs.postFooterRef.onActionHandler('follow');
                });
                break;
              case 'delete':
                this.$checkAuthDeco(() => {
                  this.deleteDialogStatus = true;
                });
                break;
              case 'accuse':
                if (checkEnv().isSwitchOn) {
                  this.accusePopupStatus = true;
                } else {
                  this.$checkAuthDeco(() => {
                    this.accusePopupStatus = true;
                  });
                }
                break;
              case 'block':
                postApi.postAction({
                  postId: this.postInfo.id,
                  postType: this.postInfo.type,
                  postCreatorId: this.postInfo.creatorId,
                  userId: (this.userInfo && this.userInfo.uid) || 0,
                  groupId: this.postInfo.groupId,
                  action: 'block',
                }).then(() => {
                  this.$toast('将为你减少此内容推荐');
                })
                  .catch((err) => {
                    logger.err('postAction', err);
                    this.$toast('将为你减少此内容推荐');
                  });
                break;
              case 'WXSession':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXSession',
                });
                break;
              case 'WXTimeline':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXTimeline',
                });
                break;
              case 'QQFriend':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQFriend',
                });
                break;
              case 'QQZone':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQZone',
                });
                break;
              case 'custom':
                if (parseInt(res2.data.id, 10) === 1) {
                  this.isShowManageDialog = true;
                }
                break;
              default:
                break;
            }
          }
          // 处理上报
          if (res2.data.type) {
            switch (res2.data.type) {
              case 'follow':
                break;
              case 'delete':
                this.reportData({
                  pageid: '1501',
                  moduleid: 32,
                  eventtype: 3,
                  extendid: 1,
                }, '答案详情页点击删除按钮');
                break;
              case 'accuse':
                this.reportData({
                  pageid: '1501',
                  moduleid: 12,
                  eventtype: 3,
                  extendid: 1,
                }, '答案详情页点击举报');
                break;
              case 'WXSession':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 2,
                }, '分享到微信点击');
                break;
              case 'WXTimeline':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 3,
                }, '分享到微信朋友圈点击');
                break;

              case 'QQFriend':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 4,
                }, '分享到QQ好友点击');
                break;
              case 'QQZone':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 5,
                }, '分享到QQ空间点击');
                break;
              case 'custom':
                if (parseInt(res2.data.id, 10) === 1) {
                  this.reportData({
                    operid: '1501000830301',
                  }, '内容详情页操作面板管理按钮点击');
                }
                break;
              default:
                break;
            }
          }
        });
      return true;
    },
    onAccuseClickHandler() {
      if (checkEnv().isSwitchOn) {
        this.accusePopupStatus = true;
      } else {
        this.$checkAuthDeco(() => {
          this.accusePopupStatus = true;
        });
      }
    },
    onAccuseClickHandlerReport() {
      this.reportData({
        moduleid: 12,
        eventtype: 3,
        extendid: 1,
      }, '答案详情页点击举报');
    },
    // 获取帖子的封面图片
    getPostCoverUrl(postInfo) {
      return (postInfo && postInfo.pics && postInfo.pics.info && postInfo.pics.info[0] && postInfo.pics.info[0].url) || '';
    },
    // 删除帖子
    onDeleteDialogConfirmHandler() {
      if (this.deletePostLoading) return false;
      this.deletePostLoading = true;
      postApi.deletePost({
        id: this.postId,
      }).then(() => {
        this.$toast('删除成功');
        this.sendNotice({
          operate: 'delete',
          data: this.postInfo,
        });
        popBack();
      })
        .catch(() => {
          this.$toast('删除失败');
        })
        .finally(() => {
          this.deletePostLoading = false;
        });
      return true;
    },
    // 编辑回答
    onEditAnswerClickHandler() {
      //
      const pisList = (this.postInfo.pics && this.postInfo.pics.info && this.postInfo.pics.info.map(item => ({
        width: item.width || 0,
        height: item.height || 0,
        url: `tgcimg:${item.url}`,
      }))) || [];
      const editAnswerParams = {
        parentId: this.questionId, // 问题id
        postId: this.postInfo.id,
        groupId: this.questionInfo.groupId,
        title: this.questionInfo.title, // 问题标题
        content: this.postInfo.content, // 回答内容
        picList: pisList, // 回答图片url, tgcimg协议
      };
      logger.info('postAnswer Edit', editAnswerParams);
      postAnswer(editAnswerParams).then((answerInfo) => {
        logger.debug('postAnswer res', answerInfo);
        this.postInfo = answerInfo;
        this.sendNotice({ operate: 'editAnswer', data: answerInfo });
      })
        .catch((err) => {
          logger.error('postAnswer err', err);
        });
    },
    // 通知其他页面
    sendNotice({
      operate, // 操作，delete postComment postAnswer postAction
      data,
    }) {
      if (compareVersion('1.3') >= 0) {
        // 1.3版本支持全局通信
        sendGlobalNotification('g_updatePost', {
          operate,
          id: this.postId,
          data,
        });
      } else {
        // 通知hippy页面
        noticeSend({
          noticeId: 'HippyNotification',
          noticeEvent: 'webUpdatePost',
          noticeParams: {
            operate,
            id: this.postId,
            data,
          },
        }).then(() => {
          // console.log('发送hippy通知成功', res);
        });
      }
    },
    onEditAnswerBtnLayout() {
      // 曝光
      this.reportData({
        moduleid: 21,
        eventtype: 2,
        extendid: 1,
      }, '回答详情页修改回答按钮曝光');
    },
    onEditAnswerClickHandlerReport() {
      this.reportData({
        moduleid: 21,
        eventtype: 3,
        extendid: 2,
      }, '回答详情页修改回答按钮点击');
    },

    onOpenTextHandler() {
      this.isOpenText = true;
      this.contentMaxHeight = this.defaultMaxHeight;
    },
    onCloseTextHandler() {
      this.isOpenText = false;
      this.contentMaxHeight = 355;
    },
    getContentShareModuleOrder() {
      postApi.getContentShareModuleOrder(this.groupId)
        .then((res) => {
          if (res.ret === 0 && res.moduleOrder?.length) {
            this.moduleOrder = res.moduleOrder;
          } else {
            // 只有评论
            this.moduleOrder = this.defaultModuleOrder;
          }
        })
        .catch((err) => {
          logger.err('getContentShareModuleOrder err', err);
          this.moduleOrder = this.isApp ? this.defaultModuleOrder : [1, 2, 3, 4];
        });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
